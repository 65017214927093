
const Dialog = () => {
    return <>
        <div className="sc-bdVaJa LPcxt" data-reach-dialog-overlay="" style={{ opacity: 1, display: "none" }}>
            <div aria-modal="true" role="dialog" tabIndex={-1} aria-label="dialog" className="sc-bwzfXH iiXYOL"
                data-reach-dialog-content="">
                <div className="sc-cMhqgX gUQEWC">
                    <div className="sc-cmthru jJAhga">
                        <div className="sc-bsbRJL fRcQRh"><svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className="sc-hZSUBg fRJrUU">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg></div>
                        <div className="sc-iuJeZd hRZQmk" color="blue">
                            <div className="sc-gqPbQI fhSmUE">Back </div>
                        </div>
                        <div className="sc-esOvli jebOoi">
                            <div className="sc-hrWEMg bHjlF">
                                <div className="sc-gwVKww jEMAel">
                                    <div className="sc-iQNlJl dJZYYq">
                                        <div className="sc-hXRMBi deLgHH">
                                            <div className="loading">I </div>
                                            <div className="sc-epnACN jwEAlI" style={{ display: "none", textAlign: "center" }}>Connect Manually </div>
                                        </div>
                                    </div>
                                </div><button id="connect-METAMASK" className="sc-kPVwWT sc-kfGgVZ sc-kIPQKe dQoUpv">
                                    <div className="sc-esjQYD iGptdp">
                                        <div color="#E8831D" className="sc-RefOD jsJmJE">MetaMask </div>
                                        <div className="sc-iQKALj GTKuw">This session is encrypted. </div>
                                    </div>
                                    <div className="sc-bwCtUz lgYJCf"><img className="firstImg" src="" alt="Icon" style={{ width: "24px" }} /></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Dialog